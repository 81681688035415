.icon-notify {
  mask: url("../img/icon-notify.svg") no-repeat 50% 50%;
}

.icon-moveFile {
  mask: url("../img/icon-moveFile.svg") no-repeat 50% 50%;
}

.icon-pencil {
  background-color: #9b247f;
  mask: url("../img/icon-pencil.svg") no-repeat 50% 50%;
}

.icon-notify {
    mask: url("../img/icon-notify.svg") no-repeat 50% 50%;
  }

  .icon-moveFile {
    mask: url("../img/icon-moveFile.svg") no-repeat 50% 50%;
  }

  .icon-pencil {
    background-color: #9b247f;
    mask: url("../img/icon-pencil.svg") no-repeat 50% 50%;
  }

  .icon-bluebeam {
    background-color: #0083db !important;
    mask: url("../img/icon-bluebeam.svg") no-repeat 50% 50%;
  }

  .icon-action-anticlockwise {
    background-color: #9b247f;
    mask: url("../img/icon-action_superseded.svg") no-repeat 50% 50%;
  }

  .icon-action_forinfo {
    background-color: #9b247f;
    mask: url("../img/icon-for_info.svg") no-repeat 50% 50%;
  }
  .icon-action-basic_todo {
    width: 60px;
    height: 60px;
    background-color: #aaaaaa;
    mask: url("../img/icon-basic_todo.svg") no-repeat 50% 50%;
  }

  .icon-blank_doc {
    width: 20px;
    height: 20px;
    background-color: #00b5e2;
    mask: url("../img/document_blank.svg") no-repeat 50% 50%;
  }

  .icon-image_doc {
    width: 20px;
    height: 20px;
    background-color: #00b5e2;
    mask: url("../img/document_image.svg") no-repeat 50% 50%;
  }

  .icon-star {
    width: 24px;
    height: 24px;
    background-color: #9b247f;
    mask: url("../img/action_star.svg") no-repeat 50% 50%;
  }

  .icon-action_download {
    width: 24px;
    height: 24px;
    background-color: #00b5e2;
    mask: url("../img/action_download.svg") no-repeat 50% 50%;
  }

  .icon-action_new-submittal {
    width: 24px;
    height: 24px;
    background-color: #9b247f;
    mask: url("../img/action_new-submittal.svg") no-repeat 50% 50%;
  }
.icon-action-anticlockwise {
  background-color: #9b247f;
  mask: url("../img/icon-action_rotate_anticlockwise.svg") no-repeat
    50% 50%;
}

.icon-action_superseded {
  background-color: #9b247f;
  mask: url("../img/icon-action_superseded.svg") no-repeat 50% 50%;
}

.icon-action_forinfo {
  background-color: #9b247f;
  mask: url("../img/icon-for_info.svg") no-repeat 50% 50%;
}
.icon-action-basic_todo {
  width: 60px;
  height: 60px;
  background-color: #aaaaaa;
  mask: url("../img/icon-basic_todo.svg") no-repeat 50% 50%;
}

.icon-blank_doc {
  width: 20px;
  height: 20px;
  background-color: #00b5e2;
  mask: url("../img/document_blank.svg") no-repeat 50% 50%;
}

.icon-image_doc {
  width: 20px;
  height: 20px;
  background-color: #00b5e2;
  mask: url("../img/document_image.svg") no-repeat 50% 50%;
}

.icon-star {
  width: 24px;
  height: 24px;
  background-color: #9b247f;
  mask: url("../img/action_star.svg") no-repeat 50% 50%;
}

.icon-action_download {
  width: 24px;
  height: 24px;
  background-color: #00b5e2;
  mask: url("../img/action_download.svg") no-repeat 50% 50%;
}

.icon-action_new-submittal {
  width: 24px;
  height: 24px;
  background-color: #9b247f;
  mask: url("../img/action_new-submittal.svg") no-repeat 50% 50%;
}

.icon-cus_green {
  background-color: #008768 !important;
}

//doc icons
.icon-document_bluebeam {
  width: 24px;
  height: 24px;
  background-color: #0083DB;
  mask: url("../img/icon-document_bluebeam.svg") no-repeat 50% 50%;
}

.icon-pmc_disposition {
  width: 24px;
  height: 24px;
  background-color: #9b247f;
  mask: url("../img/icon-pmc_disposition.svg") no-repeat 50% 50%;
}

  .icon-cus_green {
    background-color: #008768!important;
  }

.icon-bluebeam_app {
  width: 16px;
  height: 16px;
  background-color: #fff;
  mask: url("../img/bluebeam_app.svg") no-repeat 50% 50%;
}

.arrow-return-right {
  background-color: #00b5e2;
  mask: url("../img/arrow-return-right.svg") no-repeat
    50% 50%;
}

.dash-square {
  background-color: #00b5e2;
  mask: url("../img/dash-square.svg") no-repeat
    50% 50%;
}
.plus-square {
  background-color: #00b5e2;
  mask: url("../img/plus-square.svg") no-repeat
    50% 50%;
}