@media print {
  footer,
  nav,
  aecom-header,
  aecom-left-nav {
    display: none;
  }

  .app-body {
    padding-top: 0px !important;
    // height: auto;
    overflow: visible !important;
  }
  .no-print{
    display: none !important;
  }

  .filters-wrapper,
  .header-action,
  .sort::before {
    display: none !important;
  }
  .report-margin {
    margin: 0px !important;
    padding-top: 0px !important;
  }

  .no-print-margin {
    margin: 0px !important;
    width: 612pt !important;
    padding: 0px !important;
  }
  * {
    position: relative;
  }
  .app-page {
    overflow: hidden !important;
  }
  body {
    zoom: 100%;
    background-color: #000000;
  }

  td {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .content,
  body {
    overflow: visible !important;
    overflow-y: visible !important;
    height: auto;
  }

  @page {
    margin: 0;
    size: 612pt 792pt;
    // size: A4 landscape !important;
    // -webkit-print-color-adjust: exact !important;   __<<ngThemingMigrationEscapedComment0>>__
    // color-adjust: exact !important;                 __<<ngThemingMigrationEscapedComment1>>__
  }
}

@import "node_modules/@aecom/core/src/assets/styles/core.scss";
@import "./icons.scss";

@font-face {
  font-family: 'signatureFont';
  src: url("./../font/Harshita.otf") format("truetype");
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.content {
  overflow: hidden;
  height: 100%;
}

body {
  font-family: "urw-din-semi-condensed", "Barlow Semi Condensed", sans-serif;
  background-color: #fff;
}

a {
  text-decoration: none !important;
}

.uppercase {
  text-transform: uppercase;
}

#sidebarwrapper {
  position: fixed;
  z-index: 2;
  left: 0px;
  top: 40px;
  bottom: 0;
  background-color: #111;
}

.app-left-nav .wrapper {
  width: 100%;
  overflow: hidden;
}

.appLogo {
  mask: url("../img/appLogo.svg") no-repeat 50% 50%;
  background-color: #fff;
  width: 159px;
  height: 16px;
}

.browser .node.root-node,
.nav-browser .children .child-node {
  text-transform: capitalize;
}

.app-left-nav,
.app-header .button.show,
.app-header .button:hover {
  background-color: #111;
}

.form-item {
  margin: 0;
}

.btn-tab {
  width: 100%;
  height: 40px;
  color: #000;

  &.bg-grey-e {
    color: #999;
  }
}

.noItem-body {
  height: 250px;
  margin: auto;
  padding-top: 60px;
}

.noItem-body > div {
  width: 100%;
  text-align: center;
}

.noItem-icon-row {
  width: 60px !important;
  height: 60px !important;
}

.noItem-title-row {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #aaaaaa;
  margin-top: 20px;
}

.noTableFilter .table-container .filters-wrapper {
  display: none;
}

.listRFI_tr th {
  color: #777777 !important;
}

.selectUserTable .table-container {
  background-color: rgba(255, 255, 255, 0) !important;
}

.selectUser_th {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  color: #000000 !important;
}

.selectUser_tr_hover {
  line-height: 40px !important;
  overflow: auto !important;
  height: 40px !important;
  border-bottom: 1px solid #eeeeee !important;
}

.selectUser_tr_hover:hover {
  background: rgba(0, 181, 226, 0.1) !important;
}

.selectUser_td_Item,
.assignUser_td_Item {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  color: #000000 !important;
}

.assignUser_tr_hover {
  line-height: 40px !important;
  overflow: auto !important;
  height: 40px !important;
  border-bottom: 1px solid #eeeeee !important;
}

.header-actions .unchange {
  pointer-events: none;
}

.cusTab .tab-control .tabs .tab.selected {
  color: #000;
  background-color: rgba(0, 181, 226, 0.1);
}

.cusTab .tab-control .tabs {
  border-bottom: 1px solid #00b5e2 !important;
}

.cusTab .tab-control .tabs div.tab {
  min-width: 120px !important;
  border-bottom: none !important;
  color: #999 !important;
  font-weight: 500;
  font-size: 17px;
}

.cusTab .tab-control .tabs .tab:last-child {
  margin-left: auto;
  min-width: 50px !important;
  color: #fff !important;
  background-color: #078283 !important;
}

.cusTab .tab-control .tabs .tab:last-child.selected {
  color: #fff !important;
  background-color: #078283 !important;
}

.cusTab .tab-control .tabs .tab:hover {
  background-color: rgba(0, 181, 226, 0.1) !important;
  color: #000 !important;
}

.itemBlock label {
  font-style: normal;
  font-weight: 500;
  font-size: 17px !important;
  line-height: 20px;
  color: #777;
}

.matchToTab .form-group .header {
  height: 32px;
}

.icon-purple {
  background-color: #9b247f;
}
.icon-blubeam-blue {
  background-color: #0083db !important;
}

.title-icon-bluebeam {
  background-color: #0083db !important;
}

.hideFileList .upload-list {
  display: none;
}

.form-group-header {
  padding: 4px 0;
  height: 32px;
  margin-top: 0 !important;
}

.btn.solid.sm {
  text-transform: none;
  min-width: 62px;
  height: auto;
  font-size: 14px;
  color: #fff;
  padding: 3px 10px !important;
  border-radius: 0;
  line-height: 1;
}

.priority-btn-ml {
  margin-left: 14px;
}
// .sub-status {
//   display: flex;
//   align-items: center;

//   span + div {
//     display: flex;
//   }

//   .btn.sm {
//     margin-left: 8px;
//     padding: 0;
//   }

//   .icon {
//     margin: 0;
//     background-color: #9b247f;
//   }

//   .icon.disabled {
//     background-color: #777;
//   }
// }

.mat-progress-bar-buffer {
  background-color: #ddd;
}

.mat-progress-bar-fill::after {
  background-color: #9b247f;
}

.bbj-progress-bar {
  --mdc-linear-progress-active-indicator-color: #9b247f; //progress bar color
  --mdc-linear-progress-track-color: #ddd; // background color
}

.bluebeam-information {
  background-color: #fff;
  border: 1px solid #0083db;
  padding: 20px;

  .form-group-header {
    display: none;
  }

  .form-group .form-item {
    margin-top: 0;
    margin-bottom: 32px;
  }
}

// temporaty fix
table.list.cus.list-view-page thead > tr {
  th {
    position: relative;
    line-height: 1.5;

    &::before {
      position: absolute;
      top: calc(50% - 9px);
    }
  }
}
//Contract icons
.qn_contract_icon {
  background: url("../img/report-header-images/queens-icon.svg") no-repeat 50%
    50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.bx_contract_icon {
  background: url("../img/report-header-images/bronx-icon.svg") no-repeat 50%
    50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.bk_contract_icon {
  background: url("../img/report-header-images/brooklyn-icon.svg") no-repeat 50%
    50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.mn_contract_icon {
  background: url("../img/report-header-images/manhattan-icon.svg") no-repeat
    50% 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

//product icon
.submittal_icon {
  background: url("../img/product-icons/BBJ_product_icon-Submittal.svg")
    no-repeat 50% 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.rfi_icon {
  background: url("../img/product-icons/BBJ_product_icon-RFI.svg") no-repeat 50%
    50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.altBottonColor {
  background-color: #adc6cc;
}
.newReviewBottonColor {
  background-color: rgb(0, 226, 150);
}
//List view
.no-hover,
.no-hover:hover {
  color: #fff !important;
  pointer-events: none !important;
  cursor: default !important;
  span,
  .btn {
    cursor: default !important;
  }
}

.table-container {
  table.table thead > tr > th {
    box-shadow: none;
  }

  .filters .pages > span {
    color: #000;
  }

  .footer {
    margin-bottom: -40px;
  }
}

.hiddenTextOutterBox {
  white-space: nowrap;
  overflow: hidden;
  .hiddenText {
    text-overflow: ellipsis;
  }
}
.gray777 .input-checkbox span {
  color: #777777;
}

.bbj_onboarding_icon {
  background-color: #00b5e2;
  mask: url("../icons/bbj_onboarding_icon.svg") no-repeat 50% 50%;
}

.bbj_product_icon_settings {
  background-color: #BE2150;
  mask: url("../icons/bbj_product_icon_settings.svg") no-repeat 50% 50%;
}
.icon-action_app_support {
  background-color: #00b5e2;
}
.form-group .form-item {
  margin-top: 12px;
}
.h237 {
  .input-upload {
    height: 237px;
    display: flex;
    overflow: hidden;
    align-content: baseline;
    flex-direction: column;
    
    .upload-list {
      overflow: auto;
      height: 100%;
      flex: 1;
    }
  }
}

.warningFont {
  color: red;
  font-weight: bold;
}

.btn.solid.disabled.selectedBtn {
  cursor: not-allowed;
  background-color: #ddd;
  color: #aaa;
}
.btn.solid.disabled.unSelectedBtn {
  cursor: not-allowed;
  background-color: #eee;
  color: #999;
}

.ng-tooltip {
  text-align: left;
}

.cbxReopen .input-checkbox, .rdoReopen .input-radio, .rdoReopen .input-div, .cbxReopen .input-div {
  background-color: #F4F4F4;
  border: 1px solid #DDDDDD;
}

.rdoReopen .input-div, .cbxReopen .input-div {
  width: 100%;
  height: 40px;
  align-items: center;
  display: flex;
}

.rdoReopen .input-div input[type="radio"], .cbxReopen .input-div input[type="checkbox"] {
  margin-left: 12px;
  margin-right: 8px;
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

.cbxReopen .input-checkbox {
  padding-left: 12px;
}

.cbxReopen .input-checkbox span, .rdoReopen .input-radio span {
  color: black;
}

.hideHeader .form-group-header {
  display: none;
}
.headerSubTitle .form-group-header {
  display: block;
  height: auto;
}
.mb4 {
  margin-bottom: 4px;
}
.w100 {
  width: 100%;
}

.form-group-header-with-subtitle {
  .form-group-header {
    display: block;
    height: 56px;
  }
}

// app-reopen-modal {
//   height: 100%;
//   aecom-modal {
//     height: 100%;
//     display: flex;
//     .modal {
//       max-height: 100%;
//       margin: auto;
//       display: flex;
//       .modal-content {
//         height: 100%;
//         .modal-body {
//           overflow-y: auto;
//           overflow-x: hidden;
//         }
//       }
//     }
//   }
// }